.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 4rem;
    align-items: center;
    height: 20rem;
    justify-content: center;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}
.footer-blur-l{
    width: 26rem;
    height: 12rem;
    bottom: 0;
    right: 15%;
    background: red;
    filter: blur(200px);
}
.footer-blur-r{
    width: 26rem;
    height: 12rem;
    bottom: 0;
    left: 15%;
    background: rgb(255, 115, 0);
    filter: blur(180px);
}